export default {
  banner: 'Баннеры',
  city: 'Города',
  company: 'Компании',
  compilation: 'Подборки товаров',
  description: 'Описания',
  feedback: 'Отзывы',
  notification: 'Уведомления',
  order: 'Заказы',
  osago: 'Осаго',
  partner: 'Партнеры',
  'promo-code': 'Промокоды',
  'public-offer': 'Оферта',
  region: 'Регионы',
  section: 'Главная страница',
  setting: 'Контакты и ссылки',
  specification: 'Характеристики',
  travel: 'Путешествие',
  unit: 'Единицы измерения',
  brand: 'Бренды',
  role: 'Роли',
  category: 'Категории',
  dealer: 'Дилеры',
  model: 'Модели',
  product: 'Товары',
  service: 'Услуги',
  user: 'Сотрудники',
  year: 'Годы',
  common: 'Общее',
  marketplace: 'Маркетплейс',

  kasko: 'Каско',
  'wallet-transaction': 'Транзакции',
  client: 'Клиент',
};
