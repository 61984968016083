import { render, staticRenderFns } from "./AppUsersCrud.vue?vue&type=template&id=028d329c&scoped=true&"
import script from "./AppUsersCrud.vue?vue&type=script&lang=js&"
export * from "./AppUsersCrud.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "028d329c",
  null
  
)

export default component.exports