<template>
    <div>
        <!--  ROLE NAME  -->
        <b-card>
            <ValidationObserver ref="validation-observer">
                <div class="d-flex flex-wrap">
                    <div class="col-12 user__create-block p-0">
                        <!--   USERNAME   -->
                        <b-col cols="12" class="p-0">
                            <ValidationProvider
                                name="Юзернейм"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <b-form-group
                                    label="Юзернейм"
                                    label-for="username"
                                >
                                    <b-form-input
                                        v-model="username"
                                        id="username"
                                        size="md"
                                        placeholder="Введите"
                                    />
                                </b-form-group>
                                <p v-if="errors" class="validation__red">
                                    {{ errors[0] }}
                                </p>
                            </ValidationProvider>
                        </b-col>

                        <!--   PASSWORD   -->
                        <b-col cols="12" class="p-0">
                            <b-form-group label="Пароль" label-for="password">
                                <b-form-input
                                    v-model="password"
                                    id="password"
                                    size="md"
                                    placeholder="Введите"
                                />
                            </b-form-group>
                        </b-col>

                        <!--   ROLES   -->
                        <b-col cols="12" class="p-0">
                            <ValidationProvider
                                name="Роли"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <b-form-group label="Роли" label-for="roles">
                                    <v-select
                                        id="roles"
                                        v-model="selectedOption"
                                        label="name"
                                        :options="roleOptions"
                                        @input="selectedRoleId(selectedOption)"
                                    />
                                </b-form-group>
                                <p v-if="errors" class="validation__red">
                                    {{ errors[0] }}
                                </p>
                            </ValidationProvider>
                        </b-col>
                    </div>
                </div>
            </ValidationObserver>
        </b-card>

        <!--  PERMISSIONS  -->
        <b-card>
            <template>
                <div>
                    <!-- TABLE -->
                    <b-table
                        striped
                        responsive
                        sort-icon-left
                        thead-class="hidden_header"
                        :busy="isBusy"
                        :items="sortedItems"
                        :fields="fields"
                    >
                        <template #table-busy>
                            <div class="text-center text-primary my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong class="ml-1">Идет загрузка ...</strong>
                            </div>
                        </template>

                        <template #cell(type)="data">
                            <span>{{
                                translatePermissions(data.item.type)
                            }}</span>
                        </template>

                        <template #cell(all)="data">
                            <div class="pm-row-all">
                                <template v-if="data.item.header">
                                    <b>{{ data.item.header }}</b>
                                </template>
                                <template v-for="item in data.item.permissions">
                                    <div class="pm-row-one">
                                        <b-form-checkbox
                                            v-if="item.type"
                                            v-model="item.active"
                                            @change="switchAll(item)"
                                        >
                                            <span>{{
                                                slugName(item.slug)
                                            }}</span>
                                        </b-form-checkbox>
                                    </div>
                                </template>
                                <b-form-checkbox-group
                                    class="pm-row-perm"
                                    v-model="tableSelected"
                                >
                                    <template
                                        v-for="item in data.item.permissions"
                                    >
                                        <b-form-checkbox
                                            v-if="!item.type"
                                            :value="item.id"
                                        >
                                            <span>{{
                                                slugName(item.slug)
                                            }}</span>
                                        </b-form-checkbox>
                                    </template>
                                </b-form-checkbox-group>
                            </div>
                        </template>
                    </b-table>
                </div>
            </template>

            <b-button class="btn-success float-right mt-2" @click="updateUser">
                Сохранить
            </b-button>
        </b-card>
    </div>
</template>

<script>
import slugTranslations from "@/util/slugTranslations";
import sortPermissionsComputed from "@/util/sortPermissions";

import api from "@/services/api";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import {
    BSpinner,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCol,
    BTable,
    BFormCheckbox,
    BFormCheckboxGroup,
} from "bootstrap-vue";

export default {
    name: "AppUsersCreate",
    components: {
        BSpinner,
        BFormGroup,
        BFormInput,
        BButton,
        BCard,
        BCol,
        BTable,
        BFormCheckbox,
        BFormCheckboxGroup,
        ToastificationContent,
        vSelect,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            username: "",
            password: "",
            roleOptions: [],
            selectedOption: null,
            tableSelected: [],
            isBusy: false,
            selectMode: "multi",
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
            fields: [
                {
                    key: "type",
                    label: "Разрешение",
                },
                {
                    key: "all",
                    label: " ",
                },
            ],
            items: [],
            slugTranslations,
        };
    },

    async mounted() {
        await this.getPermissionsByGroup();
        await this.getOneUser();
        await this.getRoles();
    },
    computed: {
        ...sortPermissionsComputed,
    },

    watch: {
        tableSelected: {
            immediate: true,
            handler() {
                this.checkActiveAll();
            },
        },
    },

    methods: {
        async getOneUser() {
            const { id } = this.$route.params;
            this.isBusy = true;
            await api.users
                .fetchOneUser(id)
                .then((res) => {
                    this.tableSelected = res.data.permissions.map((item) => {
                        return item.id;
                    });
                    this.username = res.data.username;
                    this.selectedOption = res.data.role;
                    this.selectedOption.active = 1;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getRoles() {
            this.isBusy = true;
            await api.roles
                .fetchRoles()
                .then((res) => {
                    this.roleOptions = res.data.data.filter((item) => {
                        if (item.active) {
                            return item;
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },

        selectedRoleId(item) {
            if (item) {
                api.roles
                    .fetchOneRole(item.id)
                    .then((res) => {
                        this.tableSelected = res.data.permissions.map(
                            (item) => {
                                return item.id;
                            }
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                this.tableSelected = [];
            }
        },

        updateUser() {
            const isValid = this.$refs["validation-observer"].validate();
            if (isValid) {
                const { id } = this.$route.params;
                const { username, password, tableSelected } = this;
                const data = {
                    username,
                    password,
                    role_id: this.selectedOption.id,
                    permissions: tableSelected,
                };
                api.users
                    .updateUser(id, data)
                    .then(() => {
                        this.$router.push({ name: "users" });
                        this.showToast(
                            "success",
                            "Успешно изменено!",
                            "CheckIcon"
                        );
                    })
                    .catch((error) => {
                        console.error(error);
                        this.showToast(
                            "danger",
                            "Что-то пошло не так!",
                            "XIcon"
                        );
                    })
                    .finally(() => {});
            }
        },

        showToast(variant, text, icon) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: text,
                    icon: icon,
                    variant,
                },
            });
        },

        async getPermissionsByGroup() {
            await api.permissions
                .fetchPermissionsByGroup()
                .then((res) => {
                    this.items = res.data;
                    console.log(JSON.stringify());
                    this.items.forEach((item, index) => {
                        const ids = item.permissions.map((perm) => perm.id);
                        this.items[index].permissions.unshift({
                            slug: "all",
                            type: item.type,
                            id: ids,
                            active: false,
                        });
                        this.items[index].permissions = item.permissions.map(
                            (permission) => {
                                return { ...permission, active: false };
                            }
                        );
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        checkActiveAll() {
            this.items.forEach((item) => {
                if (item.permissions[0].slug === "all") {
                    let checkList = [];
                    item.permissions[0].id.forEach((item) => {
                        this.tableSelected.indexOf(item) !== -1
                            ? checkList.push(true)
                            : checkList.push(false);
                    });
                    checkList.every((item) => item === true)
                        ? item.permissions.map((child) => (child.active = true))
                        : item.permissions.map(
                              (child) => (child.active = false)
                          );
                }
            });
        },

        switchAll(event) {
            if (event.active) {
                event.id.forEach((item) => {
                    if (!this.tableSelected.includes(item)) {
                        this.tableSelected.push(item);
                    }
                });
            } else {
                event.id.forEach((item) => {
                    const id = this.tableSelected.indexOf(item);
                    if (id !== -1) {
                        this.tableSelected.splice(id, 1);
                    }
                });
            }
        },

        slugName(slug) {
            if (slug) {
                switch (slug.split("-")[0]) {
                    case "all":
                        return "Все";
                    case "show":
                        return "Просмотр";
                    case "create":
                        return "Создание";
                    case "update":
                        return "Изменение";
                    case "delete":
                        return "Удаление";
                }
            }
        },

        translatePermissions(item) {
            const val = this.slugTranslations[item];
            // if (!val) {
            //     return "No Translation";
            // }
            return val;
        },
    },
};
</script>

<style lang="scss" scoped>
.user__create-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0;
}

.red__error {
    color: red;
}

.pm-row-all {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;

    & .pm-row-one {
        grid-area: 1/1/2/2;

        ::v-deep .custom-checkbox {
            min-width: 120px;
            padding: 0;

            & .custom-control-label {
                padding-left: 1.8rem;
            }
        }
    }

    & .pm-row-perm {
        width: 100%;
        grid-area: 1/2/2/6;

        ::v-deep .custom-checkbox {
            min-width: 120px;
            width: 25%;
            padding: 0;
            margin: 0;

            & .custom-control-label {
                padding-left: 1.8rem;
            }
        }
    }
}
</style>
